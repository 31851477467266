<script lang="ts" setup>
  import type { IPremiers } from '~/types/movies';
  import VButton from '~/components/ui/VButton.vue';
  import MovieRating from '~/components/movies/MovieRating.vue';
  import MovieInfoGroup from '~/components/movies/MovieInfoGroup.vue';
  import MovieFavoriteButton from '~/components/movies/MovieFavoriteButton.vue';

  interface IProps {
    slide: IPremiers;
    active: boolean;
    prev: boolean;
    next: boolean;
  }
  const props = withDefaults(defineProps<IProps>(), {});
  const { activeImg, inactiveImg, rating, route, film } = usePremiers(props.slide);
  const { isRetina } = useScreen();
  const movie = computed(() => props.slide);

  const trailerModal = defineAsyncComponent(() => import('~/components/modals/trailer/Index.vue'));
  const { onReveal } = createModal(trailerModal);
</script>

<template>
  <div class="carousel-slide" :class="{ prev, active, next }">
    <v-image v-show="!active" :src="inactiveImg" transition="fade" />
    <div
      v-if="active || prev || next"
      class="carousel-slide__main"
      :class="{
        'carousel-slide__main--active' : active,
      }"
    >
      <div
        class="carousel-slide__img"
        :style="{
            backgroundImage: `url(${activeImg})`,
          }"
      />
      <div class="carousel-slide__content">
        <div class="carousel-slide__filters">
          <movie-rating v-for="(item, key) in rating" :rating="item" :name="key" />
        </div>
        <movie-info-group
          class="carousel-slide__group"
          :gap="(isRetina ? 8 : 16) + 'px'"
        >
          <span v-if="film.year">{{ film.year }}</span>
          <span v-if="film.genre">{{ film.genre }}</span>
          <span v-if="film.duration">{{ film.duration }}</span>
        </movie-info-group>
        <div class="carousel-slide__control">
          <v-button media="small" :to="route">Перейти к {{ movie?.is_film ? 'фильму' : 'сериалу' }}</v-button>
          <v-button appearance="outline" color="white" media="small" @click="onReveal({ slug: movie?.slug })">
            Трейлер
          </v-button>
          <movie-favorite-button v-model="movie.favorite" :slug="movie?.slug" :id="movie?.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .carousel-slide {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.19) 0%, rgba(255, 255, 255, 0.05) 100%), #000;
    width: 100%;
    height: 100%;
    //display: grid;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: flex-end;
    border-radius: 30px;
    overflow: hidden;
    transition: 0.5s ease;
    position: relative;
    display: flex;

    &__img {
      width: 100%;
      height: 100%;

      background: center no-repeat;
      background-size: cover;
    }

    img {
      height: 100%;
      width: 100%;
      border-radius: 34px;
    }
    &__main {
      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      background: linear-gradient(135deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%), $main_black;
      opacity: 0;
      transition: opacity 500ms;

      &--active {
        opacity: 1;
      }
    }
    &__content {
      padding: 32px;
      display: grid;
      gap: 24px;
      width: 100%;
    }
    &__group {
      line-height: 16px;
    }
    &__filters {
      display: flex;
      gap: 16px;
      align-items: center;
      white-space: nowrap;
      span {
        color: $main_white;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }
    &__control {
      display: flex;
      gap: 12px;
      button {
        width: fit-content;
      }
    }
    &__divider {
      width: 1px;
      height: 16px;
      background-color: $main_white;
      opacity: 0.5;
    }
    @media (max-width: $retina) {
      border-radius: 16px;
      &__content {
        padding: 16px;
        gap: 16px;
      }
      &__filters {
        gap: 6px;
        span {
          font-size: 12px;
          line-height: 16px;
        }
      }
      &__rating {
        padding: 4px;
        font-size: 10px;
        line-height: 12px;
        gap: 4px;
        border-radius: 4px;
        &.flex {
          display: none;
        }
        :deep(svg) {
          height: 10px;
        }
      }
      &__group {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
      &__divider {
        display: none;
      }
      .v-button {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
</style>
