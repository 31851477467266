<script lang="ts" setup="">
  import CarouselSlider from '~/components/sliders/carousel/CarouselSlider.vue';
  import { getPremiers } from '~/services/modules/movies.service';
  import CarouselSkeleton from '~/components/sliders/carousel/CarouselSkeleton.vue';

  const { data: premiers, isFetching, suspense } = getPremiers();
  onServerPrefetch(async () => await suspense());
</script>

<template>
  <section class="premiers full-width" v-if="premiers?.length">
    <v-wrapper title="Мировые премьеры" :link="{ name: 'Смотреть все', route: { name: 'premiers' } }">
      <carousel-slider v-if="premiers" class="full-width" :items="premiers ?? []" />
      <carousel-skeleton v-else />
    </v-wrapper>
  </section>
</template>

<style lang="scss" scoped>
  .premiers {
    padding: 56px 0;
    position: relative;

    .carousel-slider {
      padding-bottom: 24px;
    }
    @include gradientBackground();
    @media (max-width: $retina) {
      padding: 24px 0;
    }
  }
</style>
