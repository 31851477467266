<script lang="ts" setup></script>

<template>
  <div class="carousel-skeleton">
    <div class="carousel-skeleton__item">
      <div class="carousel-skeleton__card"></div>
    </div>
    <div class="carousel-skeleton__item centered">
      <div class="carousel-skeleton__card">
        <div class="carousel-skeleton__control">
          <v-skeleton size="medium" />
          <v-skeleton radius="32px" />
        </div>
      </div>
    </div>
    <div class="carousel-skeleton__item">
      <div class="carousel-skeleton__card"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .carousel-skeleton {
    $parent: &;
    display: flex;
    justify-content: center;
    overflow: hidden;
    &__item {
      position: relative;
      height: 620px;
      width: 300px;
      display: flex;
      justify-content: center;
      &.centered {
        #{$parent}__card {
          transform: scale(1);
          z-index: 1;
        }
      }
    }
    &__card {
      position: absolute;
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.19) 0%, rgba(255, 255, 255, 0.05) 100%), #000;
      width: 580px;
      height: 580px;
      border-radius: 32px;
      transform: scale(0.8);
      display: grid;
      align-items: flex-end;
      overflow: hidden;
    }
    &__control {
      display: grid;
      gap: 10px;
      padding: 20px;
    }
  }
</style>
