export default function ({ swiper: e, on, extendParams }: { swiper: any; on: any; extendParams: any }) {
  extendParams({
    carouselEffect: {
      opacityStep: 0.33,
      sideSlides: 2,
      scaleTranslate: 0.25,
    },
  });
  on('beforeInit', () => {
    if (e.params.effect !== 'carousel') return;

    const className = e.params.containerModifierClass + 'carousel';
    e.classNames.push(className);

    const localParams = {
      watchSlidesProgress: true,
      centeredSlides: true,
    };

    Object.assign(e.params, localParams);
    Object.assign(e.originalParams, localParams);
  });

  on('progress', () => {
    if (e.params.effect !== 'carousel') return;
    const { opacityStep, scaleTranslate } = e.params.carouselEffect;
    const scaleValues: Record<number, [number, number]> = {
      0: [1, 1],
      1: [0.465, 0.655],
      2: [0.324, 0.455],
      3: [0, 0],
    };
    const r = Math.max(Math.min(e.params.carouselEffect.sideSlides, 3), 1);
    const a = {
      1: 2,
      2: 1,
      3: 0.2,
    }[r];
    const o = {
      1: 50,
      2: 50,
      3: 67,
    }[r];
    const slideLength = e.slides.length;

    for (let index = 0; index < e.slides.length; index += 1) {
      const nodeSlide = e.slides[index];
      const progress = e.slides[index].progress;
      const progressAbs = Math.abs(progress);
      let d = 1;
      progressAbs > 1 && (d = scaleTranslate * (progressAbs - 1) * a! + 1);
      const nodeList = nodeSlide.querySelectorAll('.swiper-carousel-animate-opacity');
      const position = progress * d * o! * (e.rtlTranslate ? -1 : 1) + '%';

      let scale = scaleValues[progressAbs];
      if (!scale) {
        const floorProgress = Math.floor(progressAbs);
        const ceilProgress = Math.ceil(progressAbs);
        const getCurrentScale = (index: 0 | 1) => (scaleValues[floorProgress][index]
          - (progressAbs - floorProgress) * (scaleValues[floorProgress][index] - scaleValues[ceilProgress][index])
        );

        scale = [getCurrentScale(0), getCurrentScale(1)];
      }

      const zIndex = slideLength - Math.abs(Math.round(progress));
      nodeSlide.style.transform = `translateX(${position}) scale(${scale})`;
      nodeSlide.style.zIndex = zIndex;
      nodeSlide.style.opacity = progressAbs > r + 1 ? 0 : 1;
      nodeList.forEach((e: any) => {
        e.style.opacity = 1 - progressAbs * opacityStep;
      });
    }
  });

  on('setTransition', (_: any, time: number) => {
    if (e.params.effect === 'carousel')
      for (let index = 0; index < e.slides.length; index += 1) {
        const nodeSlide = e.slides[index];
        const nodeList = nodeSlide.querySelectorAll('.swiper-carousel-animate-opacity');
        nodeSlide.style.transitionDuration = `${time}ms`;
        nodeList.forEach((e: HTMLElement) => {
          e.style.transitionDuration = `${time}ms`;
        });
      }
  });
}
